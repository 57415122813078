<template>
  <v-dialog v-model="dialog" max-width="586px" @keydown.esc="cancel" persistent>
    <v-form ref="form" class="pa-6 text-start">
      <div class="d-flex mb-6">
        <v-img :src="require(`@/assets/icons/claim-icon.svg`)" max-width="24px" contain />
        <div
          class="text-medium font-weight-medium ml-2"
          :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-body-1'"
        >
          {{ claim.name }}
        </div>
      </div>
      <div class="text-caption">
        Edit description
        <v-textarea
          :disabled="loading"
          placeholder="Description"
          outlined
          class="rounded-lg mt-1 text-body-2"
          v-model="description"
          :rules="[$rules.required]"
        >
        </v-textarea>
      </div>
      <div class="d-flex justify-space-between">
        <v-btn outlined rounded min-width="100px" @click="dialog = false">Cancel</v-btn>
        <v-btn class="primary" rounded min-width="100px" @click="save" :loading="loading">Save</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>
      
<script>
import { mapState } from 'vuex'
export default {
  props: {
    state: Boolean,
    claim: Object,
  },
  components: {},
  computed: {
    ...mapState('auth', ['multiClaimHandler']),
  },
  methods: {
    async save() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) return
        const res = await this.multiClaimHandler.changeDescription(this.claim.id, this.description)
        if (res) {
          this.$emit('updateDone', this.description)
          this.$alert.success('Update description successed')
        } else {
          this.$alert.error('Update description failed')
        }
        this.dialog = false
      } catch (e) {
        this.$alert.error(e.message)
      } finally {
        this.loading = false
      }
    },
    async resetDefault() {
      if (this.$refs.form && !this.loading) {
        await this.$refs.form.reset()
      }
      if (!this.loading) this.description = this.claim.description
    },
  },
  created() {
    this.resetDefault()
  },
  data() {
    return {
      description: '',
      dialog: false,
      loading: false,
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
      this.resetDefault()
    },
  },
}
</script>